<template>
    <div class="projectAddress-box">
        <div class="container">
            <div class="amap-demo" id="amapDemo"></div>
        </div>
        <div class="input-item">
            <input v-show="!iconStatus" placeholder="工程名称/工程地址/车号/车牌号" v-model="searchInfo">
            <input
                v-show="iconStatus"
                placeholder="地图位置"
                id="tipinput"
                type="text"
            >
            <span @click="mapsearch">搜索</span>
        </div>
        <!-- 切换搜索框 -->
        <div class="checkout-icon">
            <el-tooltip
                v-show="iconStatus"
                class="item"
                effect="dark"
                content="去检索工程与车辆信息"
                placement="bottom"
            >
                <i @click="checkoutIcon" class="el-icon-map-location"></i>
            </el-tooltip>
            <el-tooltip
                v-show="!iconStatus"
                class="item"
                effect="dark"
                content="去检索地理位置信息"
                placement="bottom"
            >
                <i v-show="!iconStatus" @click="checkoutIcon" class="el-icon-truck"></i>
            </el-tooltip>
        </div>
        <!-- 设置车辆推送 -->
        <div class="setting-top-box" @click="setCarTime">
            <span class="iconfont">&#xe672;</span>
            <p>设置</p>
        </div>
        <!-- 总设置车联推送 -->
        <div class="setting-window" v-show="settingWindowStatus">
            <div class="setting-window-top">
                <p>定位频率设置</p>
                <span class="triangle-up"></span>
            </div>
            <div class="setting-input">
                <input v-model="setWholeTime" placeholder="请输入" type="text">
                <span>秒</span>
            </div>
            <div class="setting-btn">
                <el-button type="primary" size="mini" @click="setCarInterval(0)">
                    确定
                </el-button>
                <el-button plain size="mini" @click="settingWindowStatus = false">
                    取消
                </el-button>
            </div>
        </div>
        <!-- 单独车辆设置 -->
        <div class="setting-window setting-window-two" v-show="settingWindowStatusTwo">
            <div class="setting-window-top">
                <p>定位频率设置</p>
                <span class="triangle-up"></span>
            </div>
            <div class="setting-input">
                <input v-model="setWholeTime" placeholder="请输入" type="text">
                <span>秒</span>
            </div>
            <div class="setting-btn">
                <el-button @click="setCarInterval(0)" type="primary" size="mini">
                    确定
                </el-button>
                <el-button plain size="mini" @click="closeDanDuCar">
                    取消
                </el-button>
            </div>
        </div>
        <div id="carInfoContent" class="car-info" v-if="carInfo">
            <div class="info-header">
                <div>
                    <span class="iconfont">&#xe6a3;</span>
                    <span>{{ carInfo.plate_number }}</span>
                    <!-- eslint-disable-next-line max-len -->
                    <span v-if="[6, 7, 73].indexOf(carInfo.status) > -1" class="transportation-status transportation-status-green">运输中</span>
                    <span v-if="[77, 8].indexOf(carInfo.status) > -1" class="transportation-status transportation-status-yellow">返场中</span>
                    <span v-else class="transportation-status transportation-status-red">未运输</span>
                </div>
                <div>
                    <span @click="setWinodShow" class="iconfont setting-car-window">&#xe672;</span>
                </div>
            </div>
            <div class="info-detailed">
                <div>
                    <p>
                        <span class="iconfont">&#xe672;</span>
                        <span>车小号：{{ carInfo.plate_small_number }}</span>
                    </p>
                </div>
                <div>
                    <p>
                        <span class="iconfont">&#xe6b7;</span>
                        <span>司机姓名：{{ carInfo.driver_name }}</span>
                    </p>
                    <p>
                        电话：{{ carInfo.driver_phone }}
                    </p>
                </div>
                <div>
                    <p>
                        <span class="iconfont">&#xe667;</span>
                        <span>总运输次数：{{ carInfo.total_count }}</span>
                    </p>
                    <p>
                        今日运输次数：{{ carInfo.cur_count }}
                    </p>
                </div>
                <div>
                    <p>
                        <span class="iconfont">&#xe667;</span>
                        <span>车辆去工地：<br>{{ carInfo.to_use_time }}</span>
                    </p>
                    <p>
                        返场时间：<br>{{ carInfo.back_use_time }}
                    </p>
                </div>
            </div>
        </div>
        <div class="vehicle-dispatch">
            <ul class="project-tj">
                <li class="item">
                    <span class="lable-text">全部任务工地</span>
                    <span class="num-style">{{ projectsStatistics.all }}</span>
                </li>
                <li class="item">
                    <i class="pre-icon wys-pre-icon"></i>
                    <span class="lable-text">未运输</span>
                    <span class="num-style">{{ projectsStatistics.notWork }}</span>
                </li>
                <li class="item">
                    <i class="pre-icon wwc-pre-icon"></i>
                    <span class="lable-text">未完成</span>
                    <span class="num-style">{{ projectsStatistics.notFinished }}</span>
                </li>
                <li class="item">
                    <i class="pre-icon ysz-pre-icon"></i>
                    <span class="lable-text">运输中</span>
                    <span class="num-style">{{ projectsStatistics.trans }}</span>
                </li>
            </ul>
            <ul class="car-tj">
                <li class="item all-car">
                    <p class="num-style">
                        {{ carStatistics.all }}
                    </p>
                    <p class="lable-text">
                        全部车辆
                    </p>
                </li>
                <li class="item trans-car">
                    <p class="num-style">
                        {{ carStatistics.trans }}
                    </p>
                    <p class="lable-text">
                        运输中
                    </p>
                </li>
                <li class="item back-car">
                    <p class="num-style">
                        {{ carStatistics.back }}
                    </p>
                    <p class="lable-text">
                        返场中
                    </p>
                </li>
                <li class="item nowork-car">
                    <p class="num-style">
                        {{ carStatistics.notWork }}
                    </p>
                    <p class="lable-text">
                        未运输
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { lazyAMapApiLoaderInstance } from 'vue-amap';
import util from '@/utils/util';
import { eventBus } from '@/common/eventBus.ts';
var map;  // 地图
var placeSearch; // 搜索
var addressX, addressY; // 经纬度
var messageDom;
export default {
    props: {},
    data() {
        return {
            map: null,
            searchStr: '',
            address: '', // 地址
            longitude: '', // 经度
            latitude: '', // 纬度
            searchInfo: '',
            iconStatus: true,
            setWholeTime: 0,
            settingWindowStatus: false,
            settingWindowStatusTwo: false,
            carInfo: {},
            // 覆盖物
            southWest: {},
            northEast: {},
            bounds: {},
            rectangle: {},
            // 工程地址统计
            projectsStatistics: {
                all: 0,
                notWork: 0,
                notFinished: 0,
                trans: 0,
            }, 
            // 车辆统计
            carStatistics: {
                all: 0,
                notWork: 0,
                trans: 0,
                back: 0,
            },
            // 车辆信息
            provinces: [],
            // siginer
            carPosition: {},
            carStatus: {},
        }
    },
    watch: {
        carPosition: {
            deep: true,
            immediate: false,
            handler: function (val) {
                this.changeCarInfo(val, 'pos')
            },
        },
        carStatus: {
            deep: true,
            immediate: false,
            handler: function (val) {
                this.changeCarInfo(val, 'sta')
            },
        },
        provinces: {
            deep: true,
            immediate: false,
            handler: function (val) {
                setTimeout(() => {
                    this.searchCarInfo(val)
                }, 200)
            }
        }
    },
    created() {
        var that = this;
        this.getLocation();
        this.getProjectStatus();
        lazyAMapApiLoaderInstance.load().then(() => {
            // 初始化定位当前城市
            map = new AMap.Map('amapDemo', {
                resizeEnable: true,
                zoom: 12, // 级别
            });
            //输入提示
            var auto = new AMap.Autocomplete({
                input: "tipinput"
            });
            // 加载搜索列表
            AMap.service(["AMap.PlaceSearch"], function() {
                placeSearch = new AMap.PlaceSearch({ //构造地点查询类
                    pageSize: 1,
                    pageIndex: 1,
                    city: "全国", //城市
                    map: map,
                });
            });
            // 点标记
            map.clearMap(); 
            var markers = []; //province见Demo引用的JS文件
            var infoWindow;
            for (var i = 0; i < that.provinces.length; i += 1) {
                var marker;
                var icon
                if ([6, 7, 73].indexOf(that.provinces[i].status) > -1) {
                    icon = new AMap.Icon({
                        image: require('../../assets/images/productionManagement/blue.png'),
                        size: new AMap.Size(100, 46),
                    });
                } else if ([77, 8].indexOf(that.provinces[i].status) > -1) {
                    icon = new AMap.Icon({
                        image: require('../../assets/images/productionManagement/yellow.png'),
                        size: new AMap.Size(100, 46),
                    });
                } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(that.provinces[i].status) > -1) {
                    icon = new AMap.Icon({
                        image: require('../../assets/images/productionManagement/red.png'),
                        size: new AMap.Size(100, 46),
                    });
                }
                marker = new AMap.Marker({
                    icon: icon,
                    position: [that.provinces[i].longitude, that.provinces[i].latitude],
                    offset: new AMap.Pixel(-12,-12),
                    zIndex: 101,
                    title: that.provinces[i].plate_number,
                    map: map
                });
                // 信息窗体
                marker.setMap(map);	
                marker.data = that.provinces[i]	
                marker.on('click', function(e){
                    that.getVehicleNo(e.target.data.plate_number)
                    infoWindow.setContent(messageDom)
                    infoWindow.open(map, e.lnglat);
                });
                // 添加信息窗体
                infoWindow = new AMap.InfoWindow({
                    closeWhenClickMap: true,
                    offset: new AMap.Pixel(8,0),
                    content: "",
                    anchor: 'center'
                })
            }
			map.setFitView();
        });
    },
    mounted() {
        eventBus.$on('Monitoring', data => {
            this.carStatus = JSON.parse(data) ? JSON.parse(data) : {};
        });
        eventBus.$on('CoordinateMessage', data => {
            this.carPosition = JSON.parse(data) ? JSON.parse(data) : {};
        });
        messageDom = document.getElementById('carInfoContent')
    },
    methods: {
        // 搜索车辆信息
        searchCarInfo(val) {
            var that = this;
            map = new AMap.Map('amapDemo', {
                resizeEnable: true,
                zoom: 12, // 级别
            });
            var markers = []; //province见Demo引用的JS文件
            var infoWindow;
            for (var i = 0; i < val.length; i += 1) {
                var marker;
                var icon
                if ([6, 7, 73].indexOf(val[i].status) > -1) {
                    icon = new AMap.Icon({
                        image: require('../../assets/images/productionManagement/blue.png'),
                        size: new AMap.Size(100, 46)
                    });
                } else if ([77, 8].indexOf(val[i].status) > -1) {
                    icon = new AMap.Icon({
                        image: require('../../assets/images/productionManagement/yellow.png'),
                        size: new AMap.Size(100, 46)
                    });
                } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(val[i].status) > -1) {
                    icon = new AMap.Icon({
                        image: require('../../assets/images/productionManagement/red.png'),
                        size: new AMap.Size(100, 46)
                    });
                }
                marker = new AMap.Marker({
                    icon: icon,
                    position: [val[i].longitude, val[i].latitude],
                    offset: new AMap.Pixel(-12,-12),
                    zIndex: 101,
                    title: val[i].plate_number,
                    map: map
                });
                // 信息窗体
                marker.setMap(map);	
                marker.data = val[i]	
                marker.on('click', function(e){
                    that.getVehicleNo(e.target.data.plate_number)
                    infoWindow.setContent(messageDom)
                    infoWindow.open(map, e.lnglat);
                });
                // 添加信息窗体
                infoWindow = new AMap.InfoWindow({
                    closeWhenClickMap: true,
                    offset: new AMap.Pixel(8,0),
                    content: "",
                    anchor: 'center'
                })
            }
            map.setFitView();
        },
        // 变更车辆信息
        changeCarInfo(val, newStr) {
            var that = this;
            map = new AMap.Map('amapDemo', {
                resizeEnable: true,
                zoom: 12, // 级别
            });
            var markers = []; //province见Demo引用的JS文件
            var infoWindow;
            for (var i = 0; i < that.provinces.length; i += 1) {
                var marker;
                var icon
                // 如果新消息推送
                if (newStr === 'sta' && that.provinces[i].plate_number === val.car_license ) {
                    if ([6, 7, 73].indexOf(val.status) > -1) {
                        icon = new AMap.Icon({
                            image: require('../../assets/images/productionManagement/blue.png'),
                            size: new AMap.Size(100, 46)
                        });
                    } else if ([77, 8].indexOf(val.status) > -1) {
                        icon = new AMap.Icon({
                            image: require('../../assets/images/productionManagement/yellow.png'),
                            size: new AMap.Size(100, 46)
                        });
                    } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(val.status) > -1) {
                        icon = new AMap.Icon({
                            image: require('../../assets/images/productionManagement/red.png'),
                            size: new AMap.Size(100, 46)
                        });
                    }
                } else if (newStr === 'pos' && that.provinces[i].plate_number === val.car_license) {
                    that.provinces[i].longitude = val.longitude
                    that.provinces[i].latitude = val.latitude
                }
                marker = new AMap.Marker({
                    icon: icon,
                    position: [that.provinces[i].longitude, that.provinces[i].latitude],
                    offset: new AMap.Pixel(-12,-12),
                    zIndex: 101,
                    title: that.provinces[i].plate_number,
                    map: map
                });
                // 信息窗体
                marker.setMap(map);	
                marker.data = that.provinces[i]	
                marker.on('click', function(e){
                    that.getVehicleNo(e.target.data.plate_number)
                    infoWindow.setContent(messageDom)
                    infoWindow.open(map, e.lnglat);
                });
                // 添加信息窗体
                infoWindow = new AMap.InfoWindow({
                    closeWhenClickMap: true,
                    offset: new AMap.Pixel(8,0),
                    content: "",
                    anchor: 'center'
                })
            }
			map.setFitView();
        },
        closeDanDuCar() {
            this.settingWindowStatusTwo = false
            this.setWholeTime = 0;
        },
        //地图搜索
        mapsearch() {
            if (this.iconStatus) {
                const searchVal = $("#tipinput").val() || '';
                placeSearch.search(searchVal, function (status, result) {
                });
            } else {
                this.getLocation();
            }
        },
        // 设置车辆时间
        setCarTime() {
            this.settingWindowStatus = true;
            this.getCarInterval(0);
        },
        // 获取车辆位置配置
        getCarInterval(status) {
            const setStatus = this.carInfo.plate_number ? this.carInfo.plate_number : status;
            this.$axios
                .get(`/interfaceApi/logistics/car_manager/get_car_interval?car_license=${setStatus}`)
                .then(res => {
                    this.setWholeTime = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 显示弹窗
        setWinodShow(event) {
            const setting = document.getElementsByClassName('setting-window-two')[0];
            setting.style.left = event.clientX - 20 + 'px';
            setting.style.top = event.clientY + 20 + 'px';
            this.getCarInterval();
            this.settingWindowStatusTwo = true;
        },
        // 切换icon
        checkoutIcon() {
            const searchVal = $("#tipinput").val();
            this.iconStatus = !this.iconStatus;
            // searchVal = ''
            // this.searchInfo = ''
        },
        // 拉取车辆位置列表
        getLocation() {
            const stationId = '';
            const carType = 0;
            this.$axios
                .get(`/interfaceApi/production/vehicleinfo/get_location?stationId=${stationId}&carType=${carType}&keywords=${this.searchInfo}`)
                .then(res => {
                    this.provinces = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取当天有任务的工程
        getProjectStatus() {
            const currentDate = util.currentTimeFormat('yyyy-MM-dd');
            // `/interfaceApi/production/producttask/get_project_status?date=`+currentDate
            this.$axios
                .get('/interfaceApi/production/producttask/get_project_status?date=' + currentDate)
                .then(res => {
                    if (res) {
                        let all = 0; 
                        let notWork = 0; 
                        let notFinished = 0; 
                        let trans = 0;
                        res.map(item => {
                            if (item.status === 1) {
                                notWork += 1;
                            } else if (item.status === 3) {
                                trans += 1;
                            } else {
                                notFinished += 1;
                            }
                        });
                        all = res.length;
                        this.projectsStatistics.all = all;
                        this.projectsStatistics.notWork = notWork;
                        this.projectsStatistics.notFinished = notFinished;
                        this.projectsStatistics.trans = trans;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取站点数据集合
        // getStationData() {
        //     const CompanyId = this.$takeTokenParameters('CompanyId');
        //     const BranchId = this.$takeTokenParameters('BranchId');
        //     let urlStr = '';
        //     if (CompanyId) {
        //         urlStr = '?companyId=' + CompanyId;
        //     } else {
        //         urlStr = '?branchId=' + BranchId;
        //     }
        //     this.$axios
        //         .get('/interfaceApi/baseinfo/stationmanger/get_station_list/' + urlStr)
        //         .then(res => {
        //             if (res.length > 0) {
        //                 this.stationData = res;
        //                 this.stationData.forEach(item => {
        //                     this.circles.push(
        //                         {
        //                             center: [item.longitude, item.latitude ],
        //                             radius: 1000,
        //                             strokeColor: '#e87f8d', // 线条颜色
        //                             strokeOpacity: '0.8', // 轮廓线透明度
        //                             strokeWeight: '2', // 轮廓线宽度
        //                             fillColor: '#e87f8d', // 圆形填充颜色
        //                             fillOpacity: 0.3, // 圆形填充透明度
        //                             strokeStyle: 'dashed', // 轮廓线样式，实线:solid，虚线:dashed
        //                         },
        //                     );
        //                 });
        //             }
        //         })
        //         .catch(error => {
        //             this.$message.error(error.TypeError);
        //         });
        // },
        // 获取车辆信息
        getVehicleNo(carName) {
            this.carInfo = {};
            this.$axios
                .get(`/interfaceApi/production/vehicleinfo/get_vehicle_no?plate_number=${carName}`)
                .then(res => {
                    this.carInfo = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 拉取车辆位置列表
        // getLocation() {
        //     const stationId = '';
        //     const carType = 0;
        //     const searchVal = $("#tipinput").val();
        //     this.$axios
        //         .get(`/interfaceApi/production/vehicleinfo/get_location?stationId=${stationId}&carType=${carType}&keywords=${searchVal}`)
        //         .then(res => {
        //             const markersArr = [];
        //             if (res.length === 1) {
        //                 const newCenter = [res[0].longitude, res[0].latitude];
        //                 this.mapCenter = newCenter;
        //                 this.zoom = 13;
        //             }
        //             this.carStatistics = {
        //                 all: 0,
        //                 notWork: 0,
        //                 trans: 0,
        //                 back: 0,
        //             };
        //             this.markers = markersArr;
        //             this.carStatistics.all = this.markers.length;
        //         })
        //         .catch(error => {
        //             this.$message.error(error.ErrorCode.Message);
        //         });
        // },
        // 设置车辆回写时间间隔
        setCarInterval(status) {
            const setStatus = this.carInfo.plate_number ? this.carInfo.plate_number : status;
            const regPos = /^[0-9]+.?[0-9]*$/;
            const time = Number(this.setWholeTime);
            if (regPos.test(time)) {
                this.$axios
                    .put(`/interfaceApi/logistics/car_manager/set_car_interval/${setStatus}/${time}`)
                    .then(() => {
                        this.$message.success('操作成功');
                        this.settingWindowStatus = false;
                        this.settingWindowStatusTwo = false;
                        this.setWholeTime = 0;
                        this.setStatus = 0;
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message.error('请输入正确的时间');
            }
        },
    }
};
</script>
<style lang="stylus">
.projectAddress-box
    height 100%
    position relative
    .amap-info-close
        display none
    .container
        height 100%
        position relative
        .amap-demo
            height 100%
    .input-item
        position absolute
        z-index 2
        display flex
        height .4rem
        top .4rem
        left .4rem
        border 1px solid #ccc
        input
            height .4rem
            width 4rem
            border none
            padding-left .2rem
        span
            display block
            width .8rem
            font-size .2rem
            background #1577D2
            color #fff
            text-align center
            line-height .4rem
            cursor pointer
    .checkout-icon
        position absolute
        top: .4rem;
        left: 5.3rem;
        z-index 2
        width .4rem
        height .4rem
        font-size .34rem
        background:linear-gradient(90deg,rgba(58,150,254,1),rgba(64,182,255,1));
        i
            display flex
            align-items center
            justify-content center
            width .4rem
            height .4rem
            border-radius .04rem
            font-size .24rem
            color #fff
            cursor pointer
    .setting-top-box
        position: absolute;
        top: .4rem;
        left: 5.8rem;
        z-index 98
        background:linear-gradient(90deg,rgba(58,150,254,1),rgba(64,182,255,1));
        box-shadow:0px 2px 5px 0px rgba(0, 0, 0, 0.3);
        border-radius:.04rem;
        color #fff
        width .9rem
        height .4rem
        display flex
        align-items center
        justify-content center
        font-size .18rem
        cursor pointer
        .iconfont
            font-size .18rem
            margin-right .1rem
    .setting-window
        position absolute
        z-index 300
        top: 1.1rem;
        left 5.8rem
        background #fff
        border 1px solid #D7D7D7
        padding .2rem
        box-shadow:0px 2px 5px 0px rgba(0, 0, 0, 0.3);
        .setting-window-top
            position relative
            p
                color #333333
                font-size .18rem
            span
                position absolute
                width: 0;
                height: 0;
                border-left: .13rem solid transparent;
                border-right: .13rem solid transparent;
                border-bottom: .2rem solid #fff;
                top -.4rem
        .setting-input
            width 3.2rem
            height .42rem
            border 1px solid #D7D7D7
            display flex
            justify-content space-between
            align-items center
            margin-top .2rem
            input
                border none
                text-align center
                height 100%
                flex 2
            span
                padding-right .2rem
                font-size .2rem
        .setting-btn
            margin-top .2rem
            display flex
            align-items center
            justify-items center
            text-align center
            .el-button:first-child
                margin-left .5rem
    .vehicle-dispatch
        position absolute
        right .2rem
        top .6rem
        width 2rem
        padding .1rem
        .project-tj
            .item
                width 1.68rem
                height .58rem
                background url(../../assets/images/productionManagement/bg_normal.png) no-repeat
                background-size 100% 100%
                color #fff
                text-align center
                overflow hidden
                margin-bottom .1rem
                line-height .58rem
                text-align center
                .lable-text
                    font-size .16rem
                    float left
                    margin-right .05rem
                    padding-top .05rem
                .num-style
                    font-family QuartzLTStd
                    font-size .26rem
                    float left
                    padding-top .05rem
                .pre-icon
                    width .4rem
                    height .4rem
                    background url(../../assets/images/productionManagement/flag_red2.png) no-repeat
                    background-size 100% 100%
                    float left
                    margin .1rem
                    &.wwc-pre-icon
                        background url(../../assets/images/productionManagement/flag_blue2.png) no-repeat
                        background-size 100% 100%
                    &.ysz-pre-icon
                        background url(../../assets/images/productionManagement/flag_green2.png) no-repeat
                        background-size 100% 100%
                &:first-child
                    .lable-text
                        margin 0 .05rem 0 .2rem
        .car-tj
            margin-top .4rem
            .item
                width 1.6rem
                height .6rem
                text-align center
                background #fff
                margin-bottom .1rem
                box-shadow: 0 0 10px #888888;
                .num-style
                    font-family QuartzLTStd
                    font-size .26rem
                    padding-top .1rem
                .lable-text
                    font-size .16rem
                &.all-car
                    color #143382
                &.trans-car
                    color #0048B8
                &.back-car
                    color #FF7E00
                &.nowork-car
                    color #D6010F
    .car-info
        background #fff
        width 4.4rem
        .info-header
            display flex
            justify-content space-between
            align-items center
            font-size .18rem
            color #333333
            height .53rem
            border-bottom 1px solid #D7D7D7
            .iconfont
                cursor pointer
            .transportation-status-green
                background #66AF1C
            .transportation-status-red
                background #FA2606
            .transportation-status
                color #fff
                font-size .12rem
                font-weight 800
                padding .02rem .07rem
                margin-left .1rem
            div:first-child .iconfont
                font-size .17rem
                margin-right .1rem
            div:last-child span
                background: linear-gradient(90deg,rgba(58,151,254,1),rgba(64,180,255,1));
                -webkit-background-clip: text;
                color: transparent;
        .info-detailed
            div
                display flex
                align-items center
                font-size .16rem
                color #666666
                height .53rem
                border-bottom 1px solid #F0F0F0
                &:last-child
                    border-bottom none
                p
                    flex 1
                    .iconfont
                        font-size .16rem
                        margin-right .1rem
                        cursor pointer
</style>